<!--
    Here the navigation bar is created.
    It allows to access to each main page of the game.

    There is 6 main pages:
    - Warehouse
    - Assembly
    - Research and Development
    - Human Resources
    - Sales
    - Market study

    open an empty script tag with setup attribute
 -->
<script setup>
/*
  add a data for navigation links. It is an array of object.
  Each object has :
    - a name
    - a path
    - a boolean to know if the link is active
    - a theme (a camel case version of the name)
    - a icon (a camel case version of the name)
 */

const navigationLinks = ref([
    {
        name: 'Warehouse',
        path: '/warehouse/material-storage/',
        active: false,
        theme: 'warehouse',
        icon: 'warehouse',
    },
    {
        name: 'Assembly',
        path: '/assembly/build/',
        active: false,
        theme: 'assembly',
        icon: 'assembly',
    },
    {
        name: 'Research',
        path: '/research/overview/',
        active: false,
        theme: 'primary',
        icon: 'research',
    },
    {
        name: 'Human Resources',
        path: '/human-resources/hired-staff/',
        active: false,
        theme: 'humanResources',
        icon: 'humanResources',
    },
    {
        name: 'Sales',
        path: '/sales/map/',
        active: false,
        theme: 'sales',
        icon: 'sales',
    },
    {
        name: 'Market study',
        path: '/market-study/my-company/',
        active: false,
        theme: 'marketStudy',
        icon: 'marketStudy',
    },
    {
        name: 'Bank',
        path: '/bank/',
        active: false,
        theme: 'sales',
        icon: 'bank',
    },
]);

// end of the script tag¸
</script>

<!--
    open a template tag
 -->

<template>
    <!--
        add a div with a class of navigation.
        It is a flex container with a row direction and column gap of 32px.
        Each link is link which use button component inside a NuxtLink component.
        Variant of the button is btn--square and btn--ghost.
        add link--active if the url contains the link.name, link.name must be in lower case and space must be replaced by a dash.
        use $route.path to get the current path
    -->
    <div class="navigation flex flex-row gap-8">
        <NuxtLink
            v-for="link in navigationLinks"
            :key="link.name"
            :to="localePath(link.path)"
            class="link"
            :class="{
                'link--active': $route.path.includes(link.name.toLowerCase().replace(' ', '-')),
            }"
        >
            <Button :variant="'c-btn--square c-btn--ghost'" :icon="link.icon" :theme="link.theme" />
        </NuxtLink>
    </div>
</template>
